import styled from "styled-components"

const CLContainer = styled.section`
  padding-top: 50px;
  padding-bottom: 31px;
  background: #fff;

  @media (max-width: 767px) {
    background-color: #f8f8f8;
  }
`

export default CLContainer
