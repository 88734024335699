import styled from "styled-components"
import PropTypes from "prop-types"

const CLButton = styled.button`
  cursor: pointer;
  margin: 5px 8px 5px 0px;
  border-radius: 7px;
  display: block;
  font-size: 16px;
  font-weight: 400;
  background-color: ${({ isActive }) => (isActive ? "#6f02b5" : "#f8f8f8")};
  color: ${({ isActive }) => (isActive ? "#fff" : "#aaa")};
  border: none;
  padding: 7px 14px;
  letter-spacing: 0.2px;
  outline: none;
  font-weight: 600;

  @media (min-width: 767px) {
    margin-right: 10px;
    padding: 6px 10px;
  }
`

CLButton.propTypes = {
  isActive: PropTypes.bool,
}

CLButton.defaultProps = {
  isActive: false,
}

export default CLButton
