import styled from "styled-components"

const HeaderSubtitle = styled.p`
  max-width: 410px;
  margin: 0 auto;
  margin-top: 10px;
  line-height: 24px;
  color: #323232;
  font-family: "Fieldwork Geo";
  font-weight: 400;
  letter-spacing: 0.2px;

  @media (min-width: 767px) {
    max-width: 680px;
  }
`

export default HeaderSubtitle
