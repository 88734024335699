import styled from "styled-components"

const HeaderContainer = styled.section`
  background-image: url(https://www.kaiostech.com/wp-content/uploads/2020/01/Apps-2-scaled.jpg);
  text-align: center;
  padding-top: 36px;
  padding-bottom: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 650px;
  @media (min-width: 767px) {
    overflow: hidden;
    padding-top: 6%;
    min-height: 750px;
  }
`

export default HeaderContainer
