import styled from "styled-components"

const CLTitle = styled.h3`
  text-align: center;
  line-height: 1;
  font-family: "Fieldwork Geo", sans-serif;
  color: #323232;
  text-align: center;
  padding-bottom: 36px;
  font-size: 28px;
  font-weight: 600;

  @media (min-width: 575px) {
    padding-bottom: 35px;
    font-size: 38px;
    font-weight: 700;
  }
`

export default CLTitle
