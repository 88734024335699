import styled from "styled-components"

const CLSearchBlock = styled.div`
  padding: 16px;
  border-radius: 8px;
  border: solid 1px #dbdbdb;
  background-color: #fff;
  display: flex;
  flex-direction: column-reverse;
  @media (min-width: 767px) {
    border: 0;
    border-radius: 0;
    background-color: #fff;
    flex-direction: column;
  }
`

export default CLSearchBlock
