import styled from "styled-components"
import FlexBox from "../../../core/FlexBox"

const CLSearchFieldContainer = styled(FlexBox)`
  position: relative;
  margin-bottom: 10px;
  @media (max-width: 767px) {
    margin-top: 10px;
    justify-content: flex-start;
  }
`

export default CLSearchFieldContainer
