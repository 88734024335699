import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import FlexBox from "../../../core/FlexBox"

const ALCard = ({ category, description, developer, image, name, onClick }) => (
  <Container onClick={onClick}>
    <HeaderContainer>
      <ImageContainer>
        <Image src={image} />
      </ImageContainer>
      <DetailsContainer flexDirection="column" alignItems="flex-start">
        <Category>{category}</Category>
        <OverflowBox>
          <Name>{name}</Name>
        </OverflowBox>
        <Developer>{developer}</Developer>
      </DetailsContainer>
    </HeaderContainer>
    <DescriptionContainer>
      <Description>{description}</Description>
    </DescriptionContainer>
  </Container>
)

ALCard.propTypes = {
  category: PropTypes.string,
  description: PropTypes.string,
  developer: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
}

ALCard.defaultProps = {
  category: "",
  description: "",
  developer: "",
  image: "",
  name: "",
  onClick: PropTypes.func,
}

export default ALCard

const Container = styled.a`
  border-radius: 8px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  background: #fff;
  padding: 16px;
  margin: 0 10px 16px;
  width: 100%;
  cursor: pointer;
  text-decoration: none;

  @media (min-width: 576px) {
    width: calc(50% - 20px);
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    min-height: 312px;
  }
  @media (min-width: 1199px) {
    width: calc(25% - 20px);
    margin: 0 10px 30px;
  }
  @media (max-width: 767px) {
    min-height: 300px;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`

const ImageContainer = styled.div`
  min-width: 80px;
  min-height: 80px;
  width: 80px;
  height: 80px;
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  margin-right: 10px;

  @media (min-width: 767px) {
    margin-right: 30px;
  }

  @media (min-width: 1199px) {
    margin-right: 10px;
  }
`

const DetailsContainer = styled(FlexBox)`
  width: 100%;
`

const OverflowBox = styled.div`
  width: 100%;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    height: 0px;
  }
`

const DescriptionContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 5px;

  @media (min-width: 767px) {
    margin-top: 20px;
  }

  @media (min-width: 576px) {
    margin-bottom: auto;
  }
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Name = styled.h3`
  font-size: 17px;
  line-height: 19px;
  font-family: "Fieldwork Geo", sans-serif;
  font-weight: 600;
  color: #323232;
  margin: 3px 0;
`

const Category = styled.span`
  font-size: 17px;
  line-height: 19px;
  font-family: "Fieldwork Geo", sans-serif;
  font-weight: 600;
  color: #323232;
  margin: 3px 0;
  font-weight: 400;
  margin: 0;
`

const Developer = styled.h4`
  font-size: 12px;
  font-family: "Fieldwork Geo", sans-serif;
  font-weight: 600;
  color: #aaa;
  word-break: break-all;
`

const Description = styled.p`
  font-size: 14px;
  line-height: 21px;
  color: #323232;
`
