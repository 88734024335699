import React from "react"
import { graphql } from "gatsby"
import axios from "axios"
import queryString from "query-string"
import Layout from "../../components/core/layout"
import { FaSearch } from "react-icons/fa"
import WithLocation from "../../components/core/Navigation/WithLocation"
import HeaderTitle from "../../components/store/index/Header/HeaderTitle"
import HeaderSubtitle from "../../components/store/index/Header/HeaderSubtitle"
import HeaderContainer from "../../components/store/index/Header/HeaderContainer"
import CLContainer from "../../components/store/index/CategoryList/CLContainer"
import CLTitle from "../../components/store/index/CategoryList/CLTitle"
import CLButton from "../../components/store/index/CategoryList/CLButton"
import CLResetButton from "../../components/store/index/CategoryList/CLResetButton"
import CLSearchFieldContainer from "../../components/store/index/CategoryList/CLSearchFieldContainer"
import CLSearchBlock from "../../components/store/index/CategoryList/CLSearchBlock"
import CLSearchField from "../../components/store/index/CategoryList/CLSearchField"
import ALContainer from "../../components/store/index/AppList/AppListContainer"
import ALCard from "../../components/store/index/AppList/AppListCard"
import ALSpinner from "../../components/store/index/AppList/AppListSpinner"
import ALNotFound from "../../components/store/index/AppList/AppListNotFound"
import ResponsiveHeading from "../../components/core/ResponsiveHeading"
import Section from "../../components/core/Section"
import FlexBox from "../../components/core/FlexBox"
import SEO from "../../components/core/seo"

class ApplicationsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeCategoryIndex: 0,
      apps: [],
      appCategories: [],
      appListYCord: 0,
      displayApps: [],
      error: false,
      filterSelected: false,
      fixedSearchBar: false,
      loadingApps: false,
      loadingSpinner: "",
      navigatedIn: false,
      page: 1,
      reachedEnd: false,
      searchValue: "",
      selectedApp: null,
      size: 12,
    }
    this.appListRef = React.createRef()
  }

  componentDidMount = () => {
    const { data, location } = this.props
    const { state } = location
    const appCategories = data["dataJson"]["AppCategories"]
    const loadingSpinner =
      data["spinner"]["childImageSharp"]["fixed"]["srcWebp"]
    const activeCategoryIndex = (state && parseInt(state.navStateCategoryIndex)) || 0
    const searchValue = (state && state.navStateSearchValue) || ""
    const filterSelected =
      (activeCategoryIndex && activeCategoryIndex > 0) ||
      (searchValue && searchValue !== "")
    this.setState({ appCategories, loadingSpinner, filterSelected, activeCategoryIndex, searchValue }, async () => {
      const searchResult = await this.retrieveAppsFromSearchEndpoint();
      searchResult ? this.setState({ apps: searchResult }) : this.setState({ error: true })
    })
    if (this.appListRef.current) {
      window.addEventListener("scroll", this.handleScroll)
    }
  }

  componentWillUnmount = () => {
    if (this.appListRef.current) {
      window.removeEventListener("scroll", this.handleScroll)
    }
  }

  retrieveAppsFromSearchEndpoint = async () => {
    try {
      const { page, size, appCategories, activeCategoryIndex, searchValue } = this.state;
      const category = activeCategoryIndex > 0 ? appCategories[activeCategoryIndex] : null
      const query = searchValue ? searchValue : null
      const qs = queryString.stringify({ page, size, category, query })
      const url = `${process.env.SEARCH_ENDPOINT}${qs}`
      const result = await axios({ method: 'GET', url })
      const { data } = result
      return data["data"]
    } catch {
      return null;
    }
  }

  handleScroll = () => {
    const { loadingApps, page, reachedEnd } = this.state
    if (this.appListRef.current) {
      const appListBottom = this.appListRef.current.getBoundingClientRect()
        .bottom
      if (appListBottom < 1000 && !loadingApps && !reachedEnd) {
        this.setState({ page: page + 1, loadingApps: true }, () => {
          this.retrieveUponFilterUpdate()
        })
      }
    }
  }

  categoryFilterOnSelectHandler = idx => {
    const filterSelected = idx > 0
    const page = 1;
    this.setState(
      { activeCategoryIndex: idx, filterSelected, searchValue: "", page, apps: [], loadingApps: true, reachedEnd: false },
      () => {
        this.retrieveUponFilterUpdate()
      }
    )
  }

  searchValueOnChangeHandler = v => {
    this.setState({ searchValue: v }, () => {
    //   if (v.length === 0) {
    //     const page = 1
    //     this.setState({ page, apps: [], filterSelected: false, activeCategoryIndex: 0 }, () => {
    //       this.retrieveUponFilterUpdate()
    //     })
    //   }
    })
  }

  searchValueOnSubmitHandler = () => {
    const { searchValue } = this.state
    const trimVal = searchValue.trim()
    if (trimVal.length) {
      const page = 1
      this.setState({ page, apps: [] }, () => {
        this.retrieveUponFilterUpdate()
      })
    } else {
      this.setState({ searchValue: "" })
    }
  }

  resetFilterHandler = () => {
    this.setState(
      { activeCategoryIndex: 0, filterSelected: false, searchValue: "", reachedEnd: false, apps: [] },
      () => {
        const page = 1
        this.setState({ page }, () => {
          this.retrieveUponFilterUpdate()
        })
      }
    )
  }

  retrieveUponFilterUpdate = async () => {
    const { apps } = this.state
    const searchResult = await this.retrieveAppsFromSearchEndpoint()
    searchResult ? this.setState({ loadingApps: false, apps: [...apps, ...searchResult], error: false }) : this.setState({ loadingApps: false, reachedEnd: true })
  }

  appCardOnClickHandler = bundle_id => {
    const { navigate } = this.props
    navigate(`/store/apps?bundle_id=${bundle_id}`)
  }

  render() {
    const {
      apps,
      activeCategoryIndex,
      appCategories,
      error,
      filterSelected,
      loadingApps,
      loadingSpinner,
      searchValue,
    } = this.state;

    return (
      <Layout>
        <ResponsiveHeading>Explore KaiOS</ResponsiveHeading>
        {
          <React.Fragment>
            <SEO title="Explore KaiOS • Apps – KaiOS" lang="en" />
            <HeaderContainer>
              <Section>
                <HeaderTitle>Meet the Apps</HeaderTitle>
                <HeaderSubtitle>
                  Learn a new skill. Find out what’s trending on the other side
                  of the world. Know what friends and celebrities are up to.
                </HeaderSubtitle>
              </Section>
            </HeaderContainer>
            <CLContainer>
              <Section>
                <CLTitle appCategories={appCategories}>
                  Browse apps on KaiOS
                </CLTitle>
                <CLSearchBlock>
                  <CLSearchFieldContainer justifyContent="flex-end">
                    {filterSelected ? (
                      <CLResetButton onClick={this.resetFilterHandler}>
                        Reset
                      </CLResetButton>
                    ) : null}
                    <CLSearchField
                      type="text"
                      placeholder="Search"
                      value={searchValue}
                      onChange={e =>
                        this.searchValueOnChangeHandler(e.target.value)
                      }
                    />
                    <FaSearch
                      style={{
                        color: "#6f02b5",
                        position: "absolute",
                        right: 20,
                        cursor: "pointer",
                        bottom: 15,
                      }}
                      onClick={this.searchValueOnSubmitHandler}
                    />
                  </CLSearchFieldContainer>
                  <FlexBox>
                    {appCategories.map((aC, i) => (
                      <CLButton
                        isActive={activeCategoryIndex === i}
                        onClick={() =>
                          activeCategoryIndex === i
                            ? null
                            : this.categoryFilterOnSelectHandler(i)
                        }
                        key={aC}
                      >
                        {aC}
                      </CLButton>
                    ))}
                  </FlexBox>
                </CLSearchBlock>
              </Section>
            </CLContainer>
            <ALContainer ref={this.appListRef}>
              {(!apps.length || error) && !loadingApps ? 
                (
                  <ALNotFound>No apps found.</ALNotFound>
                ) 
                : 
                (
                  apps
                    .map((app, i) => (
                      <ALCard
                        category={app["category"]}
                        description={app["description"]}
                        developer={app["developer"]["name"]}
                        image={app["thumbnail_url"]}
                        key={`${app["name"]}${i}`}
                        name={app["name"]}
                        onClick={() =>
                          this.appCardOnClickHandler(app["bundle_id"])
                        }
                      />
                    ))
                )
              }
            </ALContainer>
            {loadingApps ? (
              <FlexBox justifyContent="center">
                <ALSpinner src={loadingSpinner} />
              </FlexBox>
            ) : null}
          </React.Fragment>
        }
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    dataJson {
      AppCategories
    }
    spinner: file(relativePath: { eq: "spinner-of-dots.png" }) {
      childImageSharp {
        id
        fixed(width: 40, height: 40) {
          base64
          tracedSVG
          aspectRatio
          srcWebp
          srcSetWebp
          originalName
        }
      }
    }
  }
`

export default WithLocation(ApplicationsPage)
