import styled from "styled-components"

const CLSearchField = styled.input`
  width: 20%;
  background-color: #fff;
  padding: 12px 40px 12px 5px;
  border: none;
  color: #aaaaaa;
  font-size: 12px;
  font-family: "Fieldwork Geo", sans-serif;
  font-weight: 400;
  border: 1px solid #aaaaaa;
  border-radius: 3px;
  @media (max-width: 767px) {
    margin-top: 5px;
    width: 100%;
  }
`

export default CLSearchField
