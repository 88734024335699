import styled from "styled-components"

const ALSpinner = styled.img`
  opacity: 0.3;
  animation: spin 4s infinite;
  margin-bottom: 20px;

  @keyframes spin {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
  }
`

export default ALSpinner
