import styled from "styled-components"

const CLResetButton = styled.button`
  background: #6f02b5;
  color: #fff;
  border-radius: 5px;
  padding: 10px 35px;
  margin-right: 25px;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  cursor: pointer;
  font-weight: 600;

  :hover {
    background: #f4f0f7;
    border: 0;
    color: #6f02b5;
  }
`

export default CLResetButton
