import styled from "styled-components"
import Section from "../../../core/Section"

const ALContainer = styled(Section)`
  background: #f8f8f8;
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 1199px) {
    padding-top: 50px;
  }
  @media (min-width: 767px) {
    padding-top: 30px;
  }
`

export default ALContainer
