import styled from "styled-components"

const HeaderTitle = styled.h1`
  color: #323232;
  font-size: 50px;
  line-height: 55px;
  font-family: "Fieldwork Geo", sans-serif;
  font-weight: 700;
  margin-top: 10px;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 38px;
    line-height: 41px;
  }
`

export default HeaderTitle
